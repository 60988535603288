<template>
	<div class="page">
		<!-- 登录页面  -->
		<el-dialog title="登录页面" center width="438px" top="20vh" :visible.sync="isShowLoginForm" :close-on-click-modal="false" @close="closeLoginform">
			<div class="logindiv flexrow flexcenter">
				<div class="loginform">
					<div class="logintype flexrow flexbetween">
						<div class="typeobj" :class="{'hover':loginindex==0}" @click="clickUserType(0)">短信验证码</div>
						<div class="typeobj" :class="{'hover':loginindex==1}" @click="clickUserType(1)">账号密码</div>
					</div>
					<div class="formobj">
					    <el-form ref="form" :model="formObj" label-width="80px">
					    	<el-form-item label="用户类型">
					    	    <el-select disabled v-model="formObj.userType" placeholder="请选择用户类型">
					    	      <el-option
					    	        v-for="(item,index) in userList"
					    	        :key="index"
					    	        :label="item.label"
					    	        :value="item.value"
					    	      />
					    	    </el-select>
					    	</el-form-item>
							<el-form-item label="手机号" v-if="loginindex==0">
							    <el-input v-model="formObj.phone" placeholder="请输入手机号"></el-input>
							</el-form-item>
							<el-form-item label="验证码" v-if="loginindex==0">
								<div class="inputdiv">
									<input class="input" v-model="formObj.captcha" placeholder="请输入验证码"></input>
									<div class="captcha" @click="getcode" v-if="!ifsetMsg">获取验证码</div>
									<div class="captcha" v-if="ifsetMsg">{{count}}s可重发</div>
								</div>
							</el-form-item>
							<el-form-item label="用户名" v-if="loginindex==1">
							    <el-input v-model="formObj.username" placeholder="请输入用户名"></el-input>
							</el-form-item>
							<el-form-item label="密码" v-if="loginindex==1">
							    <el-input v-model="formObj.password" placeholder="请输入密码"></el-input>
							</el-form-item>
							<div class="loginbtn" @click="userLogin">登录</div>
							<div class="tips flexrow flexbetween">
								<div class="text1" @click="openzhuce()">注册认证</div>
								<div class="text1" @click="showTost('请联系管理员重新获取密码~')">忘记密码</div>
							</div>
							<div class="tips flexrow flexbetween">
								<el-checkbox v-model="ifselect">我已阅读并同意<span style="color: #1370FB;text-decoration: underline;" @click="$util.routerPath('/aboutUs',{type:'user'})">《用户隐私协议》</span></el-checkbox>
							</div>
					    </el-form>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { showLoading, hideLoading } from '@/common/loading.js';
//引入状态管理
import {mapMutations} from 'vuex'
export default {
	name: 'loginForm',
	props: {
		isShow: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
	},
	data() {
		return {
			ifselect:false,//是否选择
			isShowLoginForm:false,
			loginindex:0,//0短信验证码。1账号密码
			username:"",
			password:"",
			userList:[
				{
					label:"采购商",
					value:"purchase"
				},
				{
					label:"业务代表",
					value:"business"
				},
				{
					label:"代理",
					value:"agent"
				}
			],
			formObj:{
				userType:"purchase",
				captcha:"",//验证码
				loginType:"",//登录类型 密码登录PASSWORD 手机验证码PHONE	
				password:"",
				phone:"",
				username:"",
			},
			count: '',
			timer: null,
			ifsetMsg: false,//发送验证码
		};
	},
	created() {
		
	},
	watch:{
		isShow(data){
			this.isShowLoginForm = data
		}
	},
	methods: {
		...mapMutations(['SET_Data','SET_ISLOGIN']),
		//切换登录模式
		clickUserType(type){
			this.loginindex = type
		},
		//弹窗信息
		showTost(text){
			this.$message.warning(text);
		},
		//关闭登录弹窗的回调
		closeLoginform(){
			this.$emit("handleLoginForm",'close')
		},
		//注册
		openzhuce(){
			this.$emit("openzhuce")
		},
		//登录
		userLogin(){
			if(!this.ifselect){
				this.$message.info("请先点击同意本平台协议")
				return;
			}
			if(this.formObj.userType=="purchase"){
				//采购商
				this.purchaserLogin("purchaser/login")
			}else{
				//业务代理
				//this.purchaserLogin("represent/login")
			}
		},
		//登录
		purchaserLogin(control) {
			var _this = this
			var params = this.formObj
			if(this.loginindex==0){
				params["loginType"] = "PHONE"
				//验证码
				if(!params.phone){
					this.$message.error("请输入手机号")
					return;
				}
				if(!params.captcha){
					this.$message.error("请输入验证码")
					return;
				}
			}else{
				params["loginType"] = "PASSWORD"
				if(!params.username){
					this.$message.error("请输入用户名")
					return;
				}
				if(!params.password){
					this.$message.error("请输入密码")
					return;
				}
			}
			params["control"] = control
			showLoading();
			this.$http.post(control, params).then(function(res) {
				//数据处理
				hideLoading();
				//数据处理
				if (res.code == 200) {
					_this.SET_Data({ key: 'userType', value: params.userType})
					_this.SET_Data({ key: 'uuid', value: res.data.uuid})
					if(res.data.nickname){
						_this.SET_Data({ key: 'nickName', value: res.data.nickname})
					}
					if(res.data.picture){
						_this.SET_Data({ key: 'avatarUrl', value: res.data.picture})
					}
					if(res.data.phone){
						_this.SET_Data({ key: 'phone', value: res.data.phone})
					}
					_this.SET_Data({ key: 'sysUserInfo', value:res.data})
					_this.SET_ISLOGIN(true)
					sessionStorage.setItem('token',res.data.token);
					if(params.loginType=="PASSWORD"){
						localStorage.setItem('loginForm',JSON.stringify(params));
					}
					//子账号
					if(res.data.ppurchaserUuid&&res.data.ppurchaserUuid!="0"){
						_this.SET_Data({ key: 'ifSon', value:true})
					}
					//保存
					sessionStorage.setItem('key_state',JSON.stringify(_this.$store.state))
					//保存这次登录时间
					var logintime = new Date().getTime()
					localStorage.setItem('logintime',logintime)
					_this.$message.success("登录成功")
					_this.closeLoginform()
					//登录成功回调
					_this.$emit("loginFormSuccess")
				}else{
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取验证码
		getcode(){
			var _this = this
			if(this.ifsetMsg){
				console.log("不可重复发送验证码~")
				return false;
			}
			var params = this.formObj
			var re_phone = /^1(3|4|5|6|7|8|9)\d{9}$/;
			if(!re_phone.test(params.phone)){
				this.$message.error("请输入正确的手机号码")
				return;
			}
			showLoading();
			this.$http.post('purchaser/captcha', params).then(function(res) {
				hideLoading();
				//数据处理
				if (res.code == 200) {
					_this.$message.success("发送成功~")
					//启动验证码倒计时
					_this.counttime()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//验证码倒计时
		counttime(){
			var _this = this
			this.ifsetMsg = true
			const TIME_COUNT = 60;
			if (this.ifsetMsg) {
				this.count = TIME_COUNT;
				this.timer = setInterval(() => {
					if (_this.count > 0 && _this.count <= TIME_COUNT) {
						_this.count--;
					} else {
						clearInterval(_this.timer);
						_this.ifsetMsg = false
						_this.timer = null;
						_this.count = '';
					}
			    }, 1000);
			}
		},
		
	}
};
</script>
<style lang="scss" scoped>
/**登录页面**/
.logindiv{
	height: 100%;
	position: relative;
	z-index: 8;
	.loginform{
		width: 100%;
		background: #FFFFFF;
		// box-shadow: 0px -5px 99px rgba(0, 0, 0, 0.05);
		border-radius: 12px;
		overflow: hidden;
		.logintype{
			width: 100%;
			height: 55px;
			cursor: pointer;
			.typeobj{
				width: 50%;
				height: 55px;
				line-height: 55px;
				text-align: center;
				background: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #AAAAAA;
			}
			.hover{
				font-weight: 600;
				background: #ffffff;
				color: #333333;
				border-bottom: 1px solid #2C90D5;
			}
		}
		.formobj{
			width: 100%;
			padding: 28px 0;
			.inputdiv{
				width: 100%;
				border: 1px solid #DCDFE6;
				border-radius: 4px;
				height: 40px;
				padding: 0 15px;
				background-color: #FFF;
				display: flex;
				align-items: center;
				input{
					flex: 1;
					height: 40px;
					line-height: 40px;
					font-size: inherit;
					color: #606266;
				}
				.captcha{
					width: 118px;
					padding-left: 15px;
					font-size: inherit;
					border: none;
					border-left: 1px solid #DCDFE6;
					cursor: pointer;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
			}
			.tips{
				width: 100%;
				height: 38px;
				.text1{
					cursor: pointer;
					font-size: 14px;
					font-weight: 400;
					color: #AAAAAA;
				}
			}
			.passinput{
				margin-top: 15px;
				width: 356px;
				height: 55px;
				background: #FCFDFF;
				border: 2px solid #D8E0EB;
				border-radius: 10px;
				overflow: hidden;
				box-sizing: border-box;
				padding: 0 20px;
				input{
					width: 100%;
					height: 48px;
					line-height: 48px;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 600;
					color: #333333;
				}
			}
			.loginbtn{
				cursor: pointer;
				margin: 18px auto 0;
				width: 100%;
				height: 48px;
				background: #1672FB;
				line-height: 48px;
				text-align: center;
				border-radius: 5px;
				font-size: 14px;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
	}
}
</style>
