<template>
	<div class="page">
		<!-- 注册采购商弹窗 -->
		<el-dialog title="采购认证" center width="50%" top="5vh" :visible.sync="dialogFormRegister" :close-on-click-modal="false">
		    <el-form :model="formObj" :rules="rules">
				<el-form-item label="推荐码(业务代表/代理)" :label-width="formLabelWidth">
				   <el-input v-model="formObj.representUuid" placeholder="请输入"></el-input>
				</el-form-item>
		        <el-form-item label="企业名称" :label-width="formLabelWidth" prop="afterstar">
		           <el-input v-model="formObj.comName" placeholder="请输入"></el-input>
		        </el-form-item>
		        <el-form-item label="手机号码" :label-width="formLabelWidth" prop="afterstar">
		          <el-input type="number" v-model="formObj.contactPhone" placeholder="请输入"></el-input>
		        </el-form-item>
				<el-form-item label="采购类型" :label-width="formLabelWidth" prop="afterstar">
				    <el-radio-group v-model="formObj.purchaserType">
				      <el-radio :label="1">地级市经销商</el-radio>
				      <el-radio :label="2">诊所</el-radio>
				      <el-radio :label="3">个体药店</el-radio>
					  <el-radio :label="4">连锁药店</el-radio>
				    </el-radio-group>
				</el-form-item>
				<el-form-item label="营业执照(组织机构代码证)" :label-width="formLabelWidth" prop="afterstar">
				  <el-upload class="avatar-uploader" action="" :http-request="handlelicenseUrl" :show-file-list="false" :before-upload="beforeAvatarUpload">
				    <img v-if="formObj.licenseUrl" :src="formObj.licenseUrl" class="el-avatar">
				    <i v-else class="el-icon-plus avatar-uploader-icon" />
				    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
				  </el-upload>
				</el-form-item>
				<el-form-item label="区域" :label-width="formLabelWidth" prop="afterstar">
				    <div>
				        <el-cascader
				            size="large"
				            :options="options"
				            v-model="areaOptions"
				            @change="handleChangeArea"
				        >
				        </el-cascader>
				    </div>
				</el-form-item>
				<el-form-item label="营业地址" :label-width="formLabelWidth" prop="afterstar">
				    <div class="form_box">
				        <!-- <el-cascader
				            size="large"
				            :options="options"
				            v-model="addressOptions"
				            @change="handleChangeaddress"
				        >
				        </el-cascader>
						<div style="width: 100%;height: 8px;"></div> -->
						<el-input v-model="formObj.address" placeholder="请输入详细地址"></el-input>
				    </div>
				</el-form-item>
				<el-form-item label="收货信息" :label-width="formLabelWidth" prop="afterstar">
				    <div class="form_box">
						<el-cascader
						    size="large"
						    :options="options"
							v-model="receiviOptions"
						    @change="handleChangereceivi"
						>
						</el-cascader>
						<div style="width: 100%;height: 8px;"></div>
						<el-input v-model="formObj.receivingAddress.address" placeholder="请输入详细地址"></el-input>
						<div style="width: 100%;height: 8px;"></div>
						<el-input v-model="formObj.receivingAddress.company" placeholder="请输入收货单位"></el-input>
						<div style="width: 100%;height: 8px;"></div>
						<el-input v-model="formObj.receivingAddress.name" placeholder="请输入收货人"></el-input>
						<div style="width: 100%;height: 8px;"></div>
						<el-input type="number" v-model="formObj.receivingAddress.phone" placeholder="请输入收货人电话"></el-input>
				    </div>
				</el-form-item>
				<el-form-item label="药品经营(生产)许可证" :label-width="formLabelWidth" prop="afterstar">
				  <el-upload class="avatar-uploader" action="" :http-request="handlebusinessUrl" :show-file-list="false" :before-upload="beforeAvatarUpload">
				    <img v-if="formObj.businessUrl" :src="formObj.businessUrl" class="el-avatar">
				    <i v-else class="el-icon-plus avatar-uploader-icon" />
				    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
				  </el-upload>
				</el-form-item>
				<el-form-item label="医疗器械经营许可证" :label-width="formLabelWidth">
				  <el-upload class="avatar-uploader" action="" :http-request="handlemedicalUrl" :show-file-list="false" :before-upload="beforeAvatarUpload">
				    <img v-if="formObj.medicalUrl" :src="formObj.medicalUrl" class="el-avatar">
				    <i v-else class="el-icon-plus avatar-uploader-icon" />
				    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
				  </el-upload>
				</el-form-item>
				<el-form-item label="其他资质证明" :label-width="formLabelWidth">
				  <el-upload class="avatar-uploader" action="" :http-request="handleotherUrl" :show-file-list="false" :before-upload="beforeAvatarUpload">
				    <img v-if="formObj.otherUrl" :src="formObj.otherUrl" class="el-avatar">
				    <i v-else class="el-icon-plus avatar-uploader-icon" />
				    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
				  </el-upload>
				</el-form-item>
				<el-form-item label="发送首营资料" :label-width="formLabelWidth" prop="afterstar">
				    <el-radio-group v-model="formObj.changeFlag">
				      <el-radio :label="0">已发送</el-radio>
				      <el-radio :label="1">未发送</el-radio>
				    </el-radio-group>
					<div class="formtips" @click="$util.windowOpen('https://www.gdmede.com.cn/supportAndService/download?code=support_download')">指引：如何发送首营资料？</div>
					<div class="formtips" @click="$util.windowOpen('https://sy.gdmede.com.cn/#/login')">指引：首营平台</div>
					<div class="formtips" @click="$util.windowOpen('https://www.gdmede.com.cn/supportAndService/download?code=support_download')">指引：首营注册交换指南</div>
					<div class="formtips" @click="$util.windowOpen('https://www.gdmede.com.cn/supportAndService/download?code=support_download')">法人授权委托书模板</div>
					<div class="formtips" @click="$util.windowOpen('https://www.yzc.cn/static/img/sqwts.pdf')">示例：其他资质</div>
				</el-form-item>
				
		    </el-form>
		    <div slot="footer" class="dialog-footer">
		      <el-button v-if="istype=='add'" type="primary" @click="FormRegister">提 交 审 核</el-button>
			  <el-button v-if="istype=='edit'&&formObj.audit>=1" type="primary" @click="FormRegister">提 交 修 改</el-button>
			  <el-button v-if="istype=='edit'&&formObj.audit==0" type="primary" disabled>审核中</el-button>
		    </div>
		</el-dialog>
	</div>
</template>
<script>
import {mapState} from 'vuex'
import { regionData, CodeToText ,TextToCode} from "element-china-area-data";
import { showLoading, hideLoading } from '@/common/loading.js';
export default {
	name: 'loginForm',
	props: {
		isShow: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
		istype:{
			type: String,
			default: () => {
				return "add";
			}
		},
	},
	computed: {
		...mapState(['sysUserInfo']),
	},
	data() {
		return {
			formLabelWidth: '120px',
			rules: {
				afterstar:[
					{required: true, message: '此项为必填项', trigger: 'blur' }
			    ]
			},
			formObj:{
				afterstar:true,
				audit:0,//审核状态 0未审核 1审核通过 2驳回申请
				comName:"",
				representUuid:"",//业务、代理的uuid
				contactPhone:'',
				contactName:"",//联系人
				purchaserType:1,//采购商类型
				licenseUrl:'',//营业执照地址
				businessUrl:"",//药品经营许可证地址
				address:"",//营业地址
				addressArea:"",//营业地区
				//addressDesc:"",
				areaObj:"",//区域
				medicalUrl:"",//医疗器械许可证	string	
				otherUrl:"",//	其他资质地址
				changeFlag:1,//是否交换首营 0是 1否
				receivingAddress:{
					name:"",
					phone:"",
					company:"",//收货单位
					province:"",
					city:"",
					area:"",
					address :"",
				}
			},
			options: regionData,
			areaOptions: [],//区域省市区
			addressOptions:[],//营业地址省市区
			receiviOptions:[],//收货信息
			dialogFormRegister:false,
		};
	},
	created() {
		if(this.istype=="edit"){
			//修改认证
			this.formObj = Object.assign(this.formObj,this.sysUserInfo.apply)
			///console.log(this.sysUserInfo.apply)
			var areaList = this.formObj.areaObj.split("-")
			//console.log(areaList)
			try{
				if(areaList[0]==areaList[1]){
					areaList[1] = "市辖区"
				}
				this.areaOptions = this.convertTextToCode(areaList[0],areaList[1],areaList[2])
				console.log(this.areaOptions)
				var receivinglist = [this.formObj.receivingAddress.province,this.formObj.receivingAddress.city,this.formObj.receivingAddress.area]
				if(receivinglist[0]==receivinglist[1]){
					receivinglist[1] = "市辖区"
				}
				this.receiviOptions = this.convertTextToCode(receivinglist[0],receivinglist[1],receivinglist[2])
				console.log(this.receiviOptions)
			}catch(e){
				//TODO handle the exception
			}
		}
	},
	watch:{
		isShow(data){
			this.dialogFormRegister = data
		}
	},
	methods: {
		convertTextToCode(provinceText, cityText, regionText) {
		  let code = [];
		  if (provinceText && TextToCode[provinceText]) {
		    const province = TextToCode[provinceText];
		    code.push(province.code)
		    if (cityText && province[cityText]) {
		      const city = province[cityText];
			  code.push(city.code)
		      if (regionText && city[regionText]) {
				  code.push(city[regionText].code)
		      }
		    }
		  }
		  return code;
		},
		//关闭登录弹窗的回调
		closeLoginform(){
			this.$emit("handlezhuceiForm",'close')
		},
		//区域省市区
		handleChangeArea() {
		    let loc = [];
			console.log(this.areaOptions)
		    for (let i = 0; i < this.areaOptions.length; i++) {
		       loc.push(CodeToText[this.areaOptions[i]])
		    }
		    this.formObj.areaObj = loc.join("-")
			console.log(loc)
		},
		//营业地址
		handleChangeaddress(){
			let loc = [];
			for (let i = 0; i < this.addressOptions.length; i++) {
			   loc.push(CodeToText[this.addressOptions[i]])
			}
			this.formObj.addressArea = loc.join("-")
		},
		//收货信息
		handleChangereceivi(){
			console.log(this.receiviOptions)
			this.formObj.receivingAddress.province = CodeToText[this.receiviOptions[0]]
			this.formObj.receivingAddress.city = CodeToText[this.receiviOptions[1]]
			this.formObj.receivingAddress.area = CodeToText[this.receiviOptions[2]]
			console.log(this.formObj.receivingAddress)
		},
		//注册，采购认证
		FormRegister(){
			var _this = this
			var params = this.formObj
			if(!params.comName){
				this.$message.error("请输入企业名称")
				return;
			}
			if(!params.contactPhone){
				this.$message.error("请输入手机号码")
				return;
			}
			if(!params.areaObj){
				this.$message.error("请选择区域")
				return;
			}
			if(!params.address){
				this.$message.error("请输入营业地址")
				return;
			}
			if(!params.receivingAddress.province){
				this.$message.error("请选择收货地址省市区")
				return;
			}
			if(!params.receivingAddress.address){
				this.$message.error("请输入收货信息的详细地址")
				return;
			}
			if(!params.receivingAddress.company){
				this.$message.error("请输入收货信息的收货单位")
				return;
			}
			if(!params.receivingAddress.name){
				this.$message.error("请输入收货信息的收货人")
				return;
			}
			if(!params.receivingAddress.phone){
				this.$message.error("请输入收货信息的收货电话")
				return;
			}
			//营业执照
			if (!params.licenseUrl) {
				this.$message.error("请上传营业执照")
				return;
			}
			//药品经营许可证地址
			if (!params.businessUrl) {
				this.$message.error("请上传药品经营许可证")
				return;
			}
			params["wxUuid"] = ""
			params["picture"] = "https://yaozhongcheng.obs.cn-south-1.myhuaweicloud.com/logo.png"
			params["contactName"] = params.contactPhone
			//params["address"] = params.addressArea+"-"+params.address
			showLoading();
			this.$http.post('medicineUser/PurchaserApply/apply', params).then(function(res) {
				//数据处理
				hideLoading();
				if (res.code == 200) {
					_this.$alert('提交申请成功，请等待审核和信息通知，切勿重复提交！', '温馨提示', {
					    confirmButtonText: '确定',
					    callback: action => {
					       _this.closeLoginform()
					    }
					});
				}else{
					_this.$alert(res.message, '温馨提示', {
					    confirmButtonText: '确定',
					    callback: action => {
					       
					    }
					});
				}
			})
		},
		async handlelicenseUrl({ file }) {
		    var res = await this.$api.uploadHttp(file, {})
		    if (res && res.status == 200) {
		      this.formObj.licenseUrl = res.requestUrls[0]
		      this.$message.success('上传图片成功!')
		    } else {
		      this.$message.error('上传图失败!')
		    }
		},
		async handlebusinessUrl({ file }) {
		    var res = await this.$api.uploadHttp(file, {})
		    if (res && res.status == 200) {
		      this.formObj.businessUrl = res.requestUrls[0]
		      this.$message.success('上传图片成功!')
		    } else {
		      this.$message.error('上传图失败!')
		    }
		},
		async handlemedicalUrl({ file }) {
		    var res = await this.$api.uploadHttp(file, {})
		    if (res && res.status == 200) {
		      this.formObj.medicalUrl = res.requestUrls[0]
		      this.$message.success('上传图片成功!')
		    } else {
		      this.$message.error('上传图失败!')
		    }
		},
		async handleotherUrl({ file }) {
		    var res = await this.$api.uploadHttp(file, {})
		    if (res && res.status == 200) {
		      this.formObj.otherUrl = res.requestUrls[0]
		      this.$message.success('上传图片成功!')
		    } else {
		      this.$message.error('上传图失败!')
		    }
		},
		beforeAvatarUpload(file) {
		    // console.log(file)
		    const isLt10M = file.size / 1024 / 1024 < 5
		    if (!isLt10M) {
		      this.$message.error('上传头像图片大小不能超过 5MB!')
		      return false
		    }
		    if (file.type == 'image/jpeg' || file.type == 'image/png') {
		      console.log("准备上传")
		    } else {
		      this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
		    }
		}
	}
};
</script>
<style lang="scss" scoped>
.form_box{
	padding: 18px;
	background-color: #F9FBFF;
}
.formtips{
	font-size: 12px;
	font-weight: 400;
	color: #1672FB;
	cursor: pointer;
}
</style>
